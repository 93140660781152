import {
  InferSubjects,
  MongoAbility,
  createAliasResolver,
  createMongoAbility,
} from '@casl/ability'
import { abilitiesPlugin } from '@casl/vue'
import { whenever } from '@vueuse/core'
import { camelCase, isArray, mapKeys } from 'lodash'
import { effectScope, Plugin } from 'vue'
import { AbilitySubject } from '@/api/abilitySubject'
import { useQueryAbility } from '@/api/useAbility'
import { Rule } from '@/api/useAbility.types'

type Actions = 'index' | 'show' | 'create' | 'update' | 'destroy'
const resolveAction = createAliasResolver({
  read: ['index', 'show'],
})

type AllSubjects = InferSubjects<AbilitySubject> | 'all'
export type AppAbilityUnsafe = MongoAbility<[Actions, AllSubjects]>

export interface AppAbility extends AppAbilityUnsafe {
  can: <
    Ability extends Actions,
    Subject extends AllSubjects,
    Field extends Subject extends object ? NestedKeyOf<Subject> : string
  >(
    action: Ability,
    subject: Subject,
    field?: Field
  ) => boolean
  cannot: <
    Ability extends Actions,
    Subject extends AllSubjects,
    Field extends Subject extends object ? NestedKeyOf<Subject> : string
  >(
    action: Ability,
    subject: Subject,
    field?: Field
  ) => boolean
}

export const abilityPlugin: Plugin = {
  install(app) {
    const ability = createMongoAbility<AppAbility>([], {
      resolveAction,
      detectSubjectType: (subject) => subject.__typename,
    })

    app.use(abilitiesPlugin, ability, {
      useGlobalProperties: true,
    })
    app.config.globalProperties.$cannot = ability.cannot

    const scope = effectScope()

    app.runWithContext(() => {
      scope.run(() => {
        const { data: abilityRules } = useQueryAbility()

        whenever(
          abilityRules,
          (unprocessedRules) => {
            const { rules } = createMongoAbility(
              unprocessedRules.map(camelCaseFields).map(camelCaseConditions)
            )

            ability.update(rules as AppAbility['rules'])
          },
          {
            immediate: true,
          }
        )
      })
    })
  },
}

const camelCaseFields = (rule: Rule): Rule => ({
  ...rule,
  fields: isArray(rule.fields) ? rule.fields.map(camelCase) : rule.fields,
})

const camelCaseConditions = (rule: Rule): Rule => ({
  ...rule,
  conditions: mapKeys(rule.conditions, (value, key) =>
    key.split('.').map(camelCase).join('.')
  ),
})
