<script setup lang="ts">
  import TheActionCreate from '@/modules/action/components/TheActionCreate.vue'
  import TheConfirmationModal from '@/modules/app/components/TheConfirmationModal.vue'
  import ModalView from '@/modules/base/components/ModalView.vue'
  import { useI18nManager } from '@/modules/base/composable/useI18nManager'
  import { useLayoutApp } from '@/modules/base/composable/useLayout'
  import { ModalQuery } from '@/modules/base/composable/useModalView'
  import AppRouterGuard from './AppRouterGuard.vue'

  const { loadModuleLocale } = useI18nManager()
  const { isLoading } = loadModuleLocale('global')
  const { component: layoutAppComponent } = useLayoutApp()
</script>

<template>
  <AppRouterGuard>
    <component :is="layoutAppComponent" v-if="!isLoading">
      <router-view />
    </component>
    <ModalView :query="ModalQuery.ActionCreate">
      <TheActionCreate />
    </ModalView>
    <TheConfirmationModal />
  </AppRouterGuard>
</template>
