import { z } from 'zod'

export const ruleSchema = z.strictObject({
  action: z.union([z.string().array(), z.string()]),
  subject: z.union([z.string().array(), z.string()]),
  fields: z.union([z.string().array(), z.string()]).optional(),
  conditions: z.any().optional(),
  inverted: z.boolean().optional(),
  reason: z.string().optional(),
})

export type Rule = z.infer<typeof ruleSchema>
