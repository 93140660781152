<script setup lang="ts">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { leadDebounce } from '@/modules'

  import BaseModal from '@/modules/base/components/BaseModal.vue'
  import { useConfirmationModalStore } from '@/modules/base/composable/useConfirmation'

  const { t } = useI18n()

  const store = useConfirmationModalStore()
  const handleOnConfirm = leadDebounce<void>(() => {
    store.closeModal(true)
  })
  const handleOnCancel = leadDebounce<void>(() => {
    store.closeModal(false)
  })

  const contentWithDefaults = computed(
    () => store.modal?.content ?? t('global.modalConfirmation.disclaimer')
  )
  const labelConfirmWithDefaults = computed(
    () =>
      store.modal?.labelConfirm ?? t('global.modalConfirmation.labelConfirm')
  )
  const labelCancelWithDefaults = computed(
    () => store.modal?.labelCancel ?? t('global.modalConfirmation.labelCancel')
  )
</script>
<template>
  <BaseModal
    container-class="!w-96"
    :open="!!store.modal"
    @close="handleOnCancel"
  >
    <p
      class="prose light:prose-invert whitespace-pre-wrap text-pretty break-words text-center text-xl"
    >
      {{ contentWithDefaults }}
    </p>
    <div class="mt-10 flex flex-col items-center gap-8">
      <BaseButton
        data-testid="modal-confirmation-button"
        primary
        @click="handleOnConfirm"
      >
        {{ labelConfirmWithDefaults }}
      </BaseButton>

      <BaseButton
        data-testid="modal-confirmation-cancel-button"
        secondary
        @click="handleOnCancel"
      >
        {{ labelCancelWithDefaults }}
      </BaseButton>
    </div>
  </BaseModal>
</template>
