import { Directive } from 'vue'

export const createFocusDirective = (): Directive<
  HTMLElement,
  boolean | undefined
> => ({
  mounted: (el, binding) => {
    if (binding.value !== false) {
      el.focus()
    }
  },
})
