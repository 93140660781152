<script setup lang="ts">
  import { computed, watch, watchPostEffect } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { useCurrentCompanyQuery } from '@/api/useCompany'
  import { OnboardingStatus } from '@/api/useCompany.types'
  import { useToastNotification } from '@/modules/base/composable/useToastNotification'
  import { Route, RouteError } from '@/modules/base/config/routesConfig'

  const { t } = useI18n()
  const router = useRouter()
  const route = useRoute()

  const { data: company, isSuccess } = useCurrentCompanyQuery()

  const isOnboardingRoute = computed(() =>
    route.matched.some(({ name }) => name === Route.Onboarding)
  )
  const isOnboardingFinished = computed(
    () => company.value?.onboardingStatus === OnboardingStatus.Finished
  )
  const shouldSwitchRouteToDashboard = computed(
    () => isOnboardingRoute.value && isOnboardingFinished.value
  )
  const shouldSwitchRouteToOnboarding = computed(
    () => !isOnboardingRoute.value && !isOnboardingFinished.value
  )

  watchPostEffect(() => {
    if (!isSuccess.value || !route.name) {
      return
    }

    if (shouldSwitchRouteToDashboard.value) {
      router.replace({ name: Route.Dashboard })
    } else if (shouldSwitchRouteToOnboarding.value) {
      router.replace({ name: Route.Onboarding })
    }
  })

  // ⬇️ inform the user about routing errors ⬇️
  const { pushErrorToast } = useToastNotification()

  watch(route, (_route) => {
    const { errorType } = _route.query

    if (errorType === RouteError.AccessDenied) {
      pushErrorToast(t('global.navigation.error.accessDenied'))
      router.replace({ ..._route, query: undefined })
    }
  })
</script>

<template>
  <slot />
</template>
